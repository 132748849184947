// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-flooring-js": () => import("./../../../src/pages/flooring.js" /* webpackChunkName: "component---src-pages-flooring-js" */),
  "component---src-pages-general-js": () => import("./../../../src/pages/general.js" /* webpackChunkName: "component---src-pages-general-js" */),
  "component---src-pages-handyman-js": () => import("./../../../src/pages/handyman.js" /* webpackChunkName: "component---src-pages-handyman-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-painting-js": () => import("./../../../src/pages/painting.js" /* webpackChunkName: "component---src-pages-painting-js" */),
  "component---src-pages-remodel-js": () => import("./../../../src/pages/remodel.js" /* webpackChunkName: "component---src-pages-remodel-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

